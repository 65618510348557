import { PasswordPolicy } from 'password-sheriff'
import { charsets } from '../../node_modules/password-sheriff/lib/rules/containsAtLeast'
import i18n from '../translations'

function confirmationRule (value, [target], message) {
  if (target === '' || target === null || target === undefined) return true
  if (value === '' || value === null || value === undefined) return true

  if (value.toLowerCase() !== target.toLowerCase()) {
    return message
  }
  return true
}

function isValueAllowedRule (value, [forbidden], message) {
    if (forbidden === '' || value === null || value === undefined) return true
    if (value === '' || value === null || value === undefined) return true

    if (value.toLowerCase() === forbidden.toLowerCase()) {
      return message
    }
    return true
}

function noFutureRule (value) {
    if (value) {
      const today = new Date()
      const enteredDate = new Date(value)
      return enteredDate < today
    }
    return false
}

function noTodayRule (value) {
  if (value) {
    const today = new Date()
    const enteredDate = new Date(value)
    return enteredDate.setHours(0, 0, 0, 0) !== today.setHours(0, 0, 0, 0)
  }
  return false
}

function min16Rule (value) {
    if (value) {
      let minimum = new Date()
      minimum.setFullYear(minimum.getFullYear() - 16)
      let enteredDate = new Date(value)
      return enteredDate < minimum
    }
    return true
}

function max130Rule (value) {
    if (value) {
      const maximum = new Date()
      maximum.setFullYear(maximum.getFullYear() - 130)
      const enteredDate = new Date(value)
      return enteredDate > maximum
    }
    return true
}

function passwordLengthRule (value, message) {
  if (value && value.length > 7) {
    return true
  } else {
    return message
  }
}

function noSpacesRule (value, message) {
  var check = new RegExp('\\s+')
  if (!check.test(value)) {
    return !check.test(value)
  } else {
    return message
  }
}

function confirmPasswordRule (confirmedPassword, [existingPassword], message) {
  if (confirmedPassword === existingPassword) {
    return true
  } else {
    return message
  }
}

function noUserInfoRule (value, [email], message) {
  if (!email) {
    return true
  }

  if (value === undefined || value === null || value.length < 3) {
    return true
  }

  var splitEmail = email.split('@')
  var userInfo = splitEmail[0].toLowerCase()
  var valueLowerCase = value.toLowerCase()
  if (valueLowerCase.indexOf(userInfo) >= 0) {
    return message
  }
  return true
}

function complexityRule (choosenPassword) {
  let containsAtLeastPolicy = new PasswordPolicy({
      containsAtLeast: {
        atLeast: 3,
        expressions: [ charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters ]
      }
    })

    if (containsAtLeastPolicy.check(choosenPassword)) {
      return true
    } else {
      return invalidPasswordErroMessage(choosenPassword, i18n)
  }
}

function passwordMasterRule (choosenPassword, email, messageLength, messageSpaces, messageUserInfo) {
  var lengthRule = passwordLengthRule(choosenPassword, messageLength)
  var spacesRule = noSpacesRule(choosenPassword, messageSpaces)
  var userInfoRule = noUserInfoRule(choosenPassword, [email], messageUserInfo)
  var complexity = complexityRule(choosenPassword)
  if (lengthRule === true && spacesRule === true && userInfoRule === true && complexity === true) {
    return true
  } else {
    let messages = []
    if (lengthRule !== true) {
      messages.push(lengthRule)
    }
    if (spacesRule !== true) {
      messages.push(spacesRule)
    }
    if (userInfoRule !== true) {
      messages.push(userInfoRule)
    }
    if (complexity !== true) {
      messages.push(complexity)
    }
    return messages.join('|')
  }
}

function invalidPasswordErroMessage (choosenPassword) {
  let message = i18n.global.t('messages.complexity')
    message += `<ul class="errorList">`

    let upperCasePolicy = new PasswordPolicy({
      contains: {
        expressions: [ charsets.upperCase ]
        }
      })

    let numberPolicy = new PasswordPolicy({
      contains: {
        expressions: [ charsets.numbers ]
      }
    })

    let lowerCasePolicy = new PasswordPolicy({
      contains: {
        expressions: [ charsets.lowerCase ]
      }
    })

    let specialCharPolicy = new PasswordPolicy({
      contains: {
        expressions: [ charsets.specialCharacters ]
      }
    })
    message += getListItem(i18n.global.t('messages.specialChar'), specialCharPolicy.check(choosenPassword))
    message += getListItem(i18n.global.t('messages.lowerCase'), lowerCasePolicy.check(choosenPassword))
    message += getListItem(i18n.global.t('messages.upperCase'), upperCasePolicy.check(choosenPassword))
    message += getListItem(i18n.global.t('messages.numbers'), numberPolicy.check(choosenPassword))
    message += `<ul>`
    return message
}

function getListItem (content, green) {
  var style = green ? ' style="color:green"' : ''
  return `<li${style}>${content}</li>`
}

export {
    confirmationRule,
    isValueAllowedRule,
    noFutureRule,
    min16Rule,
    max130Rule,
    confirmPasswordRule,
    passwordMasterRule,
    noUserInfoRule,
    noSpacesRule,
    passwordLengthRule,
    complexityRule,
    noTodayRule
}
