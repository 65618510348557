<template>
    <label :class="{ 'is-error': errors.errors[fieldName], 'inactive-form-input-label': readOnly, 'form-input-label' : !readOnly }">
        <small class="d-block" :class="{ 'is-error': errors.length > 0, 'form-input-title' : !readOnly, 'inactive-form-input-title' : readOnly }">{{ fieldLabel }}</small>
        <Field :type="type" :name="fieldName" :label="fieldLabel" :readonly="disabled" v-if="!readOnly"
          :rules="rules"
          class="form-input-field d-block" />
          <div class="inactive-form-value" v-else>{{ errors.values[fieldName]}}</div>
        <small v-if="errors.errors[fieldName]" class="form-input-error" :name="fieldName" v-html="errors.errors[fieldName]"></small>
    </label>
</template>
<script>
import { Field } from 'vee-validate'
import formFields from '@/mixins/formFields'

export default {
    name: 'InputComponent',

    components: {
        Field
    },

    mixins: [
        formFields
    ],

    props: {
        errors: {
            type: Object,
            required: true
        },
        rules: {
            type: String,
            required: true,
            default: 'required'
        },
        type: {
            type: String,
            required: true,
            default: 'text'
        },
        fieldLabel: {
            type: String
        },
        fieldName: {
            type: String,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../styles/colors';
@import '../../styles/media-queries';

.form-input-label {
    position: relative;
    display: block;

    &.is-error {
        .form-input-title {
            color: $color-red-500;
        }

        .form-input-field {
            border-color: $color-red-500;
        }
    }
}

.form-input-title {
    color: $color-grey-500;
    margin-bottom: 5px;
    font-size: 0.8em;
}

.form-input-field {
    border: 1px solid $color-grey-300;
    background-color: $white;
    font-size: 1.3em;
    line-height: 1.5em;
    padding: 0.5em 1em;
    border-radius: 4px;
    width: 100%;

    @include media('<=sm') {
      margin-top: 0.2em;
      padding: 0.4em 0.3em;
    }
}

.form-input-error {
    color: $color-red-500;
    font-size: 12px;
    margin: 4px 0 0;
  }
</style>
