/* eslint-disable */
import { defineRule , configure } from 'vee-validate'
import { required, numeric, min_value, max_value, max } from '@vee-validate/rules'
import { localize, setLocale  } from '@vee-validate/i18n';
import isEmail from 'validator/lib/isEmail'
import i18n from '../translations'
import { confirmationRule, isValueAllowedRule, noFutureRule, min16Rule, max130Rule, confirmPasswordRule, passwordMasterRule, noTodayRule } from './validationRules'

configure({
  validateOnBlur: false,
  validateOnChange: true,
  validateOnInput: true
});

defineRule('email', value => isEmail(value))

defineRule('required', required)
defineRule('numeric', numeric)
defineRule('min_value', min_value)
defineRule('max_value', max_value)
defineRule('max', max)

defineRule('confirm', (value, [target]) => confirmationRule(value, [target], i18n.global.t('messages.emailConfirm')))
defineRule('disallowEmail', (value, [forbidden]) => isValueAllowedRule(value, [forbidden], i18n.global.t('messages.notUserEmail')))
defineRule('noFuture', (value) => noFutureRule(value))
defineRule('noToday', (value) => noTodayRule(value))
defineRule('min16', (value) =>  min16Rule(value))
defineRule('max130', (value) =>  max130Rule(value))

defineRule('passwordMasterRule', (value, [email]) => passwordMasterRule(value, email, i18n.global.t('messages.passwordLength'), i18n.global.t('messages.noSpaces'), i18n.global.t('messages.noUserInfo')))

defineRule('passwordConfirm', (confirmedPassword, [existingPassword]) => confirmPasswordRule(confirmedPassword, [existingPassword], i18n.global.t('messages.passwordConfirm')))

export default {

  updated() {
    configure({
      generateMessage: localize(this.$i18n.locale, this.$i18n.messages[this.$i18n.locale])
    });
    setLocale(this.$i18n.locale);
  },

  methods: {
    updateInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
